import { GlobalCSS } from '@daily/shared/components/GlobalCSS';
import getConfig from 'next/config';

function PrebuiltApp({ Component, pageProps }) {
  const { assetPrefix } = getConfig().publicRuntimeConfig;

  return (
    <>
      <GlobalCSS
        background="var(--body-bg-accent)"
        fontsBasePath={`${assetPrefix}/fonts`}
      />
      <Component {...pageProps} />
    </>
  );
}

export default PrebuiltApp;
